:root {
	/* 主题色 */
	--primary-color: #6084f6;

	/* 断点 */
	--point-xs: 576px;
	--point-xm: 768px;
	--point-md: 992px;
	--point-lg: 1200px;
}
input {
	background: none;
	outline: none;
	border: 0px;
}
input:focus {
	background: none;
	outline: none;
	border: 0px;
}
button {
	border: none;
	outline: none;
	cursor: pointer;
}

button:focus {
	border: none;
	outline: none;
}
a {
	color: var(--primary-color);
}

.container {
	width: 100%;
	max-width: var(--point-lg);
	margin: 0 auto;
	zoom: 1;
	padding: 0 0.85em;
}

.primary-color {
	color: var(--primary-color);
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
	white-space: nowrap;
}
.ant-btn-primary {
	background-color: var(--primary-color);
}

.xs-up,
.sm-up,
.md-up {
	display: none;
}
@media only screen and (min-width: 768px) {
	body {
		font-size: 16px !important;
	}
}

@media only screen and (max-width: 992px) {
	.md-up {
		display: block !important;
	}
	.md-down {
		display: none !important;
	}
}
@media only screen and (max-width: 768px) {
	.sm-up {
		display: block !important;
	}
	.sm-down {
		display: none !important;
	}
}

@media only screen and (max-width: 576px) {
	.xs-up {
		display: block !important;
	}
	.xs-down {
		display: none !important;
	}
}
