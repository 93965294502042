.home {
	position: relative;
}
.home .bg_banner {
	position: absolute;
	top: -140px;
	width: 100%;
	height: 580px;
	background: url('../images/home_bg.png') no-repeat;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}

.home .container {
	padding-top: 64px;
}

.home .title {
	position: relative;
	text-align: center;
	margin-bottom: 3em;
}
.home .title img {
	width: 80%;
	max-width: 600px;
}

.home .search-container {
	box-sizing: content-box;
}
.home .search-container .search-box {
	position: relative;
	width: 100%;
	max-width: 740px;
	margin: 0 auto;
	display: flex;
	border-radius: 20px;
	background: #fff;
}
.home .search-container .search-box input {
	height: 44px;
	line-height: 44px;
	padding: 0 12px;
	font-size: 1.2em;
	border-radius: 20px 0 0 20px;
}
.home .search-container .ant-input-group-addon {
	border-radius: 0 20px 20px 0;
}
.home .search-container .ant-input-search-button {
	height: 44px;
	line-height: 44px;
	padding: 0;
	width: 88px;
	border-radius: 0 20px 20px 0;
	background-color: rgb(7, 77, 255);
	border: none;
}
.home .search-container button:hover {
	background-color: #6184f5;
}

.home .dash-stats-container {
	margin-top: 140px;
}

.home .dash-stats-container .dash-stats-item {
	margin: 8px;
	height: 9em;
	padding: 12px;
	background: #fff;
	cursor: pointer;
}
.home .dash-stats-container .dash-states-icon {
	width: 3em;
	height: 3em;
	margin: 0 12px;
}
.home .dash-stats-container .dash-states-icon img {
	width: 100%;
	height: 100%;
}

.home .dash-stats-container .dash-stats-number {
	font-size: 1.5em;
	font-weight: 600;
}

.home .latest-block-container,
.home .latest-transaction-container {
	padding: 10px;
	margin-top: 2em;
}
.home .latest-block-container .header,
.home .latest-transaction-container .header {
	margin-bottom: 10px;
}
.home .latest-block-container .header .ant-btn-link,
.home .latest-transaction-container .header .ant-btn-link {
	font-weight: 600 !important;
	color: #f89b59;
}

.block-item,
.transtion-item {
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #eee;
	background: #fff;
	line-height: 32px;
}

.block-item label,
.transtion-item label {
	display: inline-block;
	width: 6em;
	text-align: right;
	font-weight: 400;
	color: #333333;
}
.block-item .code,
.transtion-item .code {
	min-width: 60px;
	height: 64px;
	max-width: 120px;
	padding: 0 6px;
	margin-right: 12px;
	background: var(--primary-color);
	border-radius: 3px;
	line-height: 64px;
	font-size: 1.2em;
	text-align: center;
	color: #fff;
	flex-shrink: 0;
}


@media only screen and (max-width: 992px) {
	.home .dash-stats-container {
		margin-top: 120px;
	}
}
@media only screen and (max-width: 768px) {

	.home .container {
		padding-top: 40px;
	}
	.home .dash-stats-container {
		margin-top: 48px;
	}
}

@media only screen and (max-width: 576px) {
	.home .bg_banner {
		top: -90px;
	}


	.home .dash-stats-container {
		margin: 24px -6px 0;
	}
	.home .dash-stats-container .dash-stats-item {
		margin: 6px;
	}

	.home .latest-block-container,
	.home .latest-transaction-container {
		padding: 0;
	}
}
