.flex {
	display: flex;
	align-items: center;
}

.flex.flex-align-start {
	align-items: flex-start;
}
.flex.flex-align-center {
	align-items: center;
}
.flex.flex-align-end {
	align-items: flex-end;
}

.flex.flex-justify-start {
	justify-content: start;
}
.flex.flex-justify-center {
	justify-content: center;
}
.flex.flex-justify-end {
	justify-content: flex-end;
}
.flex.flex-justify-between {
	justify-content: space-between;
}
.flex.flex-justify-stretch {
	justify-content: stretch;
}
.flex.flex-direction-row {
	flex-direction: row;
}
.flex.flex-direction-column {
	flex-direction: column;
}
.flex.flex-wrap {
	flex-wrap: wrap;
}
.flex .flex-item {
	flex: 1;
}
.flex .flex-item + .flex-item {
	margin-left: 12px;
}
