.ant-layout {
	background: #eff3fc !important;
}
.ant-layout > main {
	min-height: calc(100vh - 350px);
	padding: 20px 0;
}
header {
	padding: 24px 0;
	z-index: 10;
}

header .logo {
	height: 42px;
}
header .logo img {
	height: 100%;
	width: auto;
}
header .logo .logo-text {
	padding-left: 5px;
	color: #6084f6;
	font-weight: 600;
}
/**	
横向导航菜单
*/
header .menu-container {
	text-align: right;
}
header .menu-container ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
header .menu-container li {
	display: inline-block;
}
header .menu-container .menu-item {
	font-size: 1.1em;
	height: 36px;
	line-height: 36px;
	padding: 0 16px;
	margin: 0 0.5em;
	/* font-family: Microsoft YaHei; */
	font-weight: 400;
	transition: all 200ms;
	border-radius: 20px;
}
header .menu-container .menu-item a {
	color: #6084f6;
	transition: all 200ms;
}
header .menu-container .menu-item.active {
	background-color: #6084f6;
	border-radius: 20px;
}
header .menu-container .menu-item.active a {
	color: #fff !important;
}
header.dark .menu-container .menu-item.active {
	background-color: #fff;
}
header.dark .menu-container .menu-item.active a {
	color: #6084f6 !important;
}

/**
 小屏幕上右侧的菜单按钮
*/
header .menu-icon-container {
	display: none;
}
header .menu-icon-container .menu-icon {
	color: #353535;
	width: 42px;
	height: 42px;
}
header.dark .menu-container .menu-item a,
header.dark .menu-icon-container .menu-icon,
header.dark .menu-icon-container .menu-item a {
	color: #fff;
}
header.dark .menu-container .menu-item.active::after {
	background-color: #fff;
}

header.dark .logo .logo-text {
	color: #fff;
}

/**
*抽屉导航菜单
*/
.menu-drawer {
	display: none;
}
.menu-drawer ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.menu-drawer li {
	font-size: 1.3em;
	position: relative;
	height: 42px;
	line-height: 42px;
	font-family: Microsoft YaHei;
	font-weight: 400;
}
.menu-drawer li {
	color: #353535;
}
.menu-drawer li.active {
	color: #6084f6;
}

/**
页脚
*/
footer {
	background-color: rgb(32, 32, 32);
	color: #fff;
	z-index: 1;
	font-size: 14px;
}
footer .container {
	padding: 16px 1.5em;
}
footer h2 {
	margin-bottom: 20px;
	color: #fff;
	position: relative;
}
footer h2::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 1px;
	left: 0;
	bottom: -12px;
	background: #5c5c5c;
}

footer ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
footer ul li {
	margin: 6px 0;
	font-size: 12px;
	color: #909090;
	line-height: 1.5;
}
footer ul li a {
	color: #909090;
}

footer .qr-wrap {
	margin: 12px;
}
footer .qr-wrap img {
	width: 80px;
	height: 80px;
	display: block;
}
footer .qr-wrap p {
	margin: 0;
	line-height: 32px;
	color: #909090;
	font-size: 12px;
}

footer .copyright {
	font-size: 12px;
	color: #909090;
	text-align: center;
	position: relative;
	line-height: 1.5;
	padding: 10px 0;
	margin-top: 10px;
}
footer .copyright::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 1px;
	left: 0;
	top: 0px;
	background: #30333c;
}

@media only screen and (max-width: 992px) {
	header {
		padding: 24px 0;
	}
}
@media only screen and (max-width: 768px) {
	footer .container {
		padding: 0 1.5em;
	}
	footer h2 {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 576px) {
	header {
		padding: 12px 0;
	}
}
