.breadcrumb-component {
	font-size: 20px;
}
.breadcrumb-component .ant-breadcrumb {
	line-height: 24px;
	font-size: 18px;
}
.breadcrumb-component .ant-breadcrumb > span:last-child a {
	color: var(--primary-color);
}
