.block .table-container {
	position: relative;
	margin-top: 20px;
	min-height: 60vh;
}

.block .table-container .total {
	position: absolute;
	top: 16px;
	left: 0;
	line-height: 24px;
}

.block .ant-table {
	box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
	border-radius: 6px;
}
.block .ant-table .ant-table-container {
	border-radius: 6px;
}
.block .ant-table-thead > tr > th {
	font-size: 16px;
	color: #4d5b6c;
}
.block .ant-table-tbody tr:nth-child(2n) {
	background: #eff3fc;
}

/* 区块详情页样式 */
.block-info .block-hash label {
	margin-right: 10px;
	flex-shrink: 0;
}
.block-info .card-component {
	margin: 2em 0;
}
/*  卡片标题样式 */
.block-info .card-component .card-title {
	font-size: 1em;
	font-weight: 500;
	color: #333;
	line-height: 36px;
	padding: 12px 16px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.block-info .overview ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.block-info .overview li {
	display: flex;
	line-height: 40px;
	padding: 0 24px 0 12px;
	font-size: 0.95em;
	color: #4d5b6c;
}
.block-info .overview li:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.block-info .overview li label {
	width: 120px;
}

.trans-record {
	min-height: 300px;
}
.trans-record .ant-table-cell:first-child,
.trans-record .ant-table-cell:last-child {
	padding: 0;
}
.trans-record .ant-table-thead > tr > th {
	background: unset;
}
.trans-record .ant-table-tbody:last-child > tr > td {
	border-bottom: none;
}

@media only screen and (max-width: 576px) {
	.block-info .overview li {
		padding: 0 12px 0 0;
	}
	.block-info .overview li label {
		width: 6.5em;
		flex-shrink: 0;
	}
	.block-info .card-component {
		margin: 1.5em 0;
	}
}

@media only screen and (max-width: 768px) {
	.breadcrumb-component {
		margin-top: 2em;
	}
	.block .table-container {
		margin-top: 44px;
		min-height: 60vh;
	}

	.block .table-container .total {
		top: -24px;
	}
	.block-info .block-hash .copyDom {
		flex: 1;
		width: 0;
	}
}
