.transaction .table-container {
	position: relative;
	margin-top: 20px;
	min-height: 60vh;
}
.transaction .table-container .total {
	position: absolute;
	top: 16px;
	left: 0;
	line-height: 24px;
}
.transaction .ant-table {
	box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
	border-radius: 6px;
}
.transaction .ant-table .ant-table-container {
	border-radius: 6px;
}
.transaction .ant-table-thead > tr > th {
	font-size: 16px;
	color: #4d5b6c;
}
.transaction .ant-table-tbody tr:nth-child(2n) {
	background: #eff3fc;
}

/* 存证详情页样式 */
.transaction-info .card-component {
	margin: 2em 0;
}
/*  卡片标题样式 */
.transaction-info .card-component .card-title {
	font-weight: 500;
	color: #333;
	line-height: 36px;
	padding: 12px 16px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.transaction-info .overview ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.transaction-info .overview li {
	display: flex;
	line-height: 40px;
	padding: 0 24px 0 12px;
	font-size: 0.95em;
	color: #4d5b6c;
}
.transaction-info .overview li:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.transaction-info .overview li label {
	width: 120px;
	flex-shrink: 0;
}

@media only screen and (max-width: 576px) {
	.transaction-info .overview li {
		padding: 0 12px 0 0;
	}
	.transaction-info .overview li label {
		width: 6.5em;
		flex-shrink: 0;
	}
	.transaction-info .card-component {
		margin: 1.5em 0;
	}
}

@media only screen and (max-width: 768px) {
	.transaction .breadcrumb-component {
		margin-top: 2em;
	}
	.transaction .table-container {
		margin-top: 44px;
		min-height: 60vh;
	}

	.transaction .table-container .total {
		top: -24px;
	}
}
